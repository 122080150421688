@font-face {
  font-family: TuttiFrutti;
  src: url(./fonts//TuttiFrutti.otf);
}
@font-face {
  font-family: Coolvetica;
  src: url(./fonts/coolvetica_book.otf);
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.InputField {
  margin-top: 20px;
  cursor: pointer;
  width: 50%;
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: #ff7fbf;
  height: 24px;
  font-family: "TuttiFrutti" !important;
  color: white;
  margin: 0 auto;
  padding-top: 5px;
  font-size: 18px;
}

html,
body {
  height: 100%;
  padding-right: 0px !important;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: top;
  background-color: #ff7fbf;
  color: white;
  overflow-x: hidden !important;
  position: relative;
  // min-height: 100vh;
  background-position: top;
  font-style: normal;
  font-family: "TuttiFrutti" !important;
  font-weight: 300;
}

.MiddleLogo {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  img {
    width: 120px;
  }
}

h1 {
  font-size: 100px;
  font-weight: 400;
  margin: 0 auto;
}
.LinkButtond {
  cursor: pointer;
  width: 50%;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -10px;
  display: block;
  outline: none;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0px 4px 0px #e7569e;
  height: 40px;
  border: none;

  p {
    font-family: "TuttiFrutti" !important;
    color: #ff7fbf;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 22px;
  }
}
p {
  font-size: 30px;
  margin: 0 auto;
  margin-top: 20px;
}

.Subheader1 {
  font-size: 24px;
  margin: 0 auto;
}

.WhitelistLink {
  color: #ff7fbf !important;
  p {
    font-size: 16px !important;
    color: #ff7fbf !important;
    margin: 0 auto;
    margin-top: 18px;
  }
}

.ConnectModal {
  background-color: #ffecf5;

  h2 {
    color: #ff7fbf !important;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
  }

  button {
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: #ff7fbf;
    height: 200px;
    width: 250px;
    cursor: pointer;
  }

  img {
    width: 100px;
    height: 60px;
  }

  p {
    font-family: "TuttiFrutti";
    color: white;
    font-size: 20px;
  }
}

.HotPink {
  color: #ff7fbf !important;
}

.LightPink {
  color: #ffbadd !important;
}

.Subheader {
  font-size: 14px;
  color: #aeacad !important;
  line-height: 18px;
  width: 85%;

  a {
    color: #aeacad;
  }
}

.BorderContainer {
  width: 85%;
  max-width: 1400px;
  margin: 0 auto;
  height: 100vh;
  position: relative;
}

.AbsoluteCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.ConstructionTubby {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.TubbyScrollImg {
  position: absolute;
  bottom: 0;
  height: 512px;
  animation: moveSlideshow 100s linear infinite;
}
@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}

@media screen and (max-width: 900px) {
  .EventButtonContainer {
    width: 90% !important;
    max-width: 90% !important;
  }

  .EventButton {
    max-width: 220px !important;
    p {
      padding-top: 4px !important;
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .EventButtonContainer {
    width: 100% !important;
    max-width: 100% !important;
  }

  .EventButton {
    max-width: 300px !important;
    p {
      padding-top: 4px !important;
      font-size: 18px !important;
    }
  }
  .Reversed {
    margin-top: -20px !important;
  }
  .HomeMintButton {
    width: 100% !important;
    p {
      font-size: 12px !important;
    }
  }

  .TubbyCatsHeader,
  h1 {
    font-size: 28px;
  }
  .AboutAboutContainer {
    min-width: 400px !important;

    h2 {
      font-size: 32px !important;
    }
    p {
      margin-top: -15px;
      color: #ffbadd;
      font-size: 18px !important;
    }
  }
  .AbtHeader {
    font-size: 40px !important;
  }
  .EventHeader {
    font-size: 40px !important;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 42px;
  }

  h1 {
    font-size: 60px;
  }

  p {
    font-size: 20px;
  }
}

@media screen and (max-height: 1000px) {
  .TubbyScrollImg {
    height: 40%;
  }
}

@media screen and (max-width: 800px) {
  .TubbyScrollImg {
    height: 28%;
  }
}
@media screen and (max-width: 1000px) {
  .AbsoluteCentered {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .AbsoluteCentered {
    width: 90%;
  }
  .Subheader1 {
    font-size: 18px;
  }
  .LinkButtond {
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: -10px;
    display: block;
    outline: none;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 4px 0px #e7569e;
    height: 40px;
    border: none;

    p {
      font-family: "TuttiFrutti" !important;
      color: #ff6ab4;
      margin: 0 auto;
      padding-top: 5px;
      font-size: 22px;
    }
  }
}
@media screen and (max-width: 300px) {
  .AbsoluteCentered {
    width: 95%;
  }
  .Subheader1 {
    font-size: 18px;
  }
  .LinkButtond {
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: -10px;
    display: block;
    outline: none;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 4px 0px #e7569e;
    height: 30px;
    border: none;

    p {
      font-family: "TuttiFrutti" !important;
      color: #ff7fbf;
      margin: 0 auto;
      padding-top: 5px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .TubbyCatsHeader {
    margin-top: 0px !important;
    font-size: 60px !important;
  }
}

.Stars {
  display: none;
}

@media screen and (max-width: 600px) {
  .ConnectModal {
    width: 85%;
    padding: 30px 10px !important;
    button {
      border-radius: 8px;
      background-color: #ff7fbf;
      height: 130px;
      width: 90% !important;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }

    h2 {
      color: #ff7fbf !important;
      margin: 0 auto;
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    img {
      margin-top: -10px !important;
      width: 100px;
      height: 40px;
    }
  }

  .TubbyCatsHeader {
    margin-top: 40px !important;
  }

  .Stars {
    display: block !important;
    position: absolute;
    width: 100%;

    img {
      width: 50px;
      position: absolute;
    }

    .Stars1 {
      left: 0px;
      top: -30px;
    }

    .Stars2 {
      right: 0px;
      top: -30px;
    }
  }
}

.AboutAboutContainer {
  width: 80% !important;

  margin: 0 auto !important;
  // display: block !important;

  h2 {
    color: #ff7fbf;
    font-size: 42px;
  }
  p {
    // margin-top: -15px;
    color: #ffbadd;
    font-size: 22px;
  }

  .TeamAbout {
    a {
      color: #ffbadd;
    }
    color: #ffbadd;
    font-size: 22px;
    margin: 0 auto;
    margin-top: 12px;
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    width: 250px;
    outline: none;
    border: 3px solid #ff7fbf;
    border-radius: 50px;
    background-color: white;
    height: 45px;

    p {
      font-family: "TuttiFrutti" !important;
      color: #ff7fbf;
      margin: 0 auto;
      padding-top: 5px;
      font-size: 24px;
    }
  }
}

.AboutHeaderContainer {
  .StarsLeft,
  .StarsRight {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 160px;
    margin-top: 60px;
  }
}

@media screen and (max-height: 800px) {
  .TubbyScrollImg {
    height: 30%;
  }
}
.SecondRowTeam {
  margin-top: 40px !important;
}
@media screen and (max-width: 800px) {
  .HomeLinkCont {
    width: 80% !important;
  }
  .TubbyCatsHeader {
    font-size: 50px !important;
  }
  .HomeMintButton {
    width: 80% !important;
    p {
      font-size: 12px !important;
    }
  }
  .AbtHeader {
    font-size: 60px;
  }
  .FlexTeam {
    flex-direction: column !important;
  }
}

.MintBox {
  margin-top: 30px;
  width: 300px;
  display: inline-block;
  height: 40px;
  position: relative;
}

.NotWhitelisted {
  background-color: #ffbadd !important;

  width: 300px !important;
  p {
    font-size: 20px !important;
  }
}

.NotWhitelistedDetails {
  font-size: 14px;
  color: #ff6ab4;
}

.HomeRefButton {
  width: 100%;
  margin: 0 auto;
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: #ff7fbf;
  height: 45px;
  cursor: pointer;
  p {
    font-family: "TuttiFrutti" !important;
    color: white;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 22px;
    font-weight: 300;
  }
}

.Reversed {
  background-color: transparent !important;
  border: 3px solid #ff7fbf !important;

  p {
    color: #ff7fbf !important;
  }
}

.HomeMintButton {
  margin-top: 30px;
  cursor: pointer;
  width: 50%;
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: #ff7fbf;
  height: 45px;

  p {
    font-family: "TuttiFrutti" !important;
    color: white;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 24px;
  }
}

.EventText {
  font-size: 22px;
  font-family: "Coolvetica";
}

.EventHeader {
  color: white !important;
  padding-top: 28px;
  text-align: center;
  font-size: 60px;
  margin-bottom: 0px;
}

.EventHeader2 {
  padding-top: 14px;
  font-size: 30px !important;
}

.EventButtonContainer {
  max-width: 60%;
  margin: 0 auto;
  display: block;
}

.EventButton {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: 320px;
  outline: none;
  border: 2px solid white;
  border-radius: 50px;
  background-color: transparent;
  height: 45px;
  margin-top: 20px;
  cursor: pointer;
  p {
    font-family: "TuttiFrutti" !important;
    color: white;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 22px;
    font-weight: 300;
  }
}

// ABOUT PAGE //
.AbtHeader {
  color: #ff7fbf !important;
  padding-top: 100px;
  text-align: center;
  font-size: 80px;
  margin-bottom: 40px;
}

.TeamContainer {
  max-width: 300px;
  min-width: 200px;
  width: 100%;
}
@media screen and (max-width: 1340px) {
  .TeamContainerTop {
    width: 100% !important;
  }
}

@media screen and (max-width: 1340px) and (min-width: 800px) {
  .TeamContainer {
    min-width: 150px !important;
  }

  .TeamCard {
    height: 250px !important;
    img {
      height: 150px !important;
      margin: 0 auto;
      display: block;
    }

    .TeamInfo {
      position: absolute;
      background: #ffbadd;
      bottom: 0;
      width: 100%;
      height: 100px !important;

      h2 {
        font-size: 16px !important;
      }

      p {
        width: 80% !important;
        display: block;
        color: #ff7fbf;
        font-size: 10px !important;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .EventButtonContainer {
    width: 75%;
    max-width: 75%;
  }

  .EventButton {
    max-width: 270px;
    p {
      padding-top: 4px !important;
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .TeamCard {
    height: 350px !important;

    img {
      height: 220px !important;
    }

    .TeamInfo {
      height: 130px !important;

      .Title {
        color: white !important;
        opacity: 0.8;
        margin-bottom: 10px !important;
      }

      h2 {
        font-size: 28px !important;
      }

      p {
        width: 80%;
        color: white;
        font-size: 14px !important;
        text-align: center;
        margin: 0 auto;
        margin-top: 6px;
        margin-bottom: 5px;
      }
    }
  }
}

.TeamCard {
  margin: 0 auto;
  top: 0;
  transition: top ease 0.5s;
  background: #fff1f8;
  border: 2px solid #ff7fbf;
  box-sizing: border-box;
  box-shadow: 4px 4px 0px #ff7fbf;
  border-radius: 15px;
  height: 280px;
  position: relative;
  margin-top: 30px;

  &:hover {
    top: -10px;
  }

  img {
    height: 180px;
    margin: 0 auto;
    display: block;
  }

  .CenteredInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .TeamInfo {
    position: absolute;
    background: #ffbadd;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 0px 0px 13px 13px;

    .Title {
      color: white !important;
      opacity: 0.8;
    }

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      margin: 0 auto;
      color: #ff6ab4;
    }

    p {
      width: 80%;
      color: white;
      font-size: 12px;
      text-align: center;
      margin: 0 auto;
      margin-top: 6px;
      margin-bottom: 5px;
    }
  }
}

// ==================================================
$hamburger-types: (squeeze) !default;

// HAMBURGER MENU
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 50px !default;
$hamburger-layer-height: 5px !default;
$hamburger-layer-spacing: 8px !default;
$hamburger-layer-color: #ff7fbf !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

@if index($hamburger-types, squeeze) {
  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
      }

      &::after {
        transition: bottom 0.075s 0.12s ease,
          transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
            transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

.TubbyGif {
  border-radius: 8px;
  filter: drop-shadow(5px 5px 0px #ff7fbf);
}

.HonMember {
  img {
    border-radius: 10px;
  }

  a {
    text-decoration: none;
    color: #ff7fbf;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    display: block;
    font-size: 22px;
    margin-top: 10px;
  }
}
