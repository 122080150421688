.Linksksks {
  background: ff7fbf;
}

.LinksHeader {
  color: white !important;
  padding-top: 60px;
  text-align: center;
  font-size: 80px;
  margin-bottom: 10px;
  width: 100%;

  img {
    max-width: 180px;
  }
}

.buttonsan {
  margin: 0 auto;
  display: block;
  width: 300px;
  font-size: 14px;
  margin-top: 10px;
}
.button-56 {
  cursor: pointer;
  margin: 0 auto;
  display: block;
  width: 300px;
  height: 199px;
  background: transparent;
  border: 2px solid white;
  box-shadow: 0px 4px 0px white;
  border-radius: 10px;
  height: 50px;
  margin-top: 30px;

  p {
    font-family: "TuttiFrutti" !important;
    color: white;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 24px;
  }
}

.LinkButton {
  cursor: pointer;
  width: 90%;
  margin: 0 auto;
  margin-bottom: -10px;
  display: block;
  outline: none;
  border: none;
  border-radius: 50px;
  background-color: white;
  height: 50px;

  p {
    font-family: "TuttiFrutti" !important;
    color: #ff7fbf;
    margin: 0 auto;
    padding-top: 5px;
    font-size: 24px;
  }
}

@media screen and (max-width: 800px) {
  .LinksHeader {
    font-size: 48px;
  }

  .Linksksks {
    button {
      max-width: 200px;
    }
    p {
      max-width: 200px;
      font-size: 14px;
    }
    .buttonsan {
      font-size: 10px;
    }
  }
}

.CC0 {
  width: 90%;
  font-size: 16px;
  color: #ff7fbf;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 100px;
}
